import gql from 'graphql-tag'

export const GetDeliveriesByOrderObjectIdGQL = gql`
  query ($orderObjectId: String!) {
    getDeliveriesByOrderObjectId(orderObjectId: $orderObjectId) {
      id
      objectId
      code
      status
      delivery {
        price
        provider
        deliveryId
      }
      premiumDeliveries {
        id
      }
      waypoints {
        type
        address1
        address2
        location {
          lat
          lng
        }
        items {
          notes
          size
          category
          customId
        }
        name
        phone
      }
      driverInfo {
        name
        phone
        photo
        plateNumber
        location {
          lat
          lng
        }
      }
      completedAt
    }
  }
`
