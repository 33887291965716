import dayjs, { Dayjs } from 'dayjs'
import _ from 'lodash'
import { Component, Vue } from 'nuxt-property-decorator'

import {
  DEFAULT_FORMAT,
  DEFAULT_TZ,
  DELIVERY_TYPE,
  OPS_NOTICEBOARD_TYPE,
  ORDER_STATUS,
  PROVIDER,
  USER_ROLE,
  UtilityService,
  VEHICLE_TYPE,
  WAYPOINT_TYPE,
} from '../services'

@Component
export default class UtilityMixins extends Vue {
  public get $isMobile() {
    return this.$vuetify.breakpoint.xs
  }

  public get $isTablet() {
    return this.$vuetify.breakpoint.sm
  }

  public get $isTabletOrBelow() {
    return this.$isTablet || this.$isMobile
  }

  public get $isAdmin() {
    return this.$accessor.user?.role === USER_ROLE.ADMIN
  }

  public get $isOpsAndAbove() {
    return _.includes(
      [USER_ROLE.ADMIN, USER_ROLE.OPS],
      this.$accessor.user?.role,
    )
  }

  public $getDeliveryStatusColor(status?: string) {
    switch (status) {
      case ORDER_STATUS.BATCHING:
        return { color: 'blue', hex: '#2196F3' }
      case ORDER_STATUS.RACING:
        return { color: 'statusYellow', hex: '#f7b500' }
      case ORDER_STATUS.ACCEPTED:
        return { color: 'blue', hex: '#2196F3' }
      case ORDER_STATUS.PENDING:
        return { color: 'blue', hex: '#2196F3' }
      case ORDER_STATUS.UNFULFILLED:
        return { color: 'blue', hex: '#2196F3' }
      case ORDER_STATUS.ONGOING:
        return { color: 'blue', hex: '#2196F3' }
      case ORDER_STATUS.PICKED_UP:
        return { color: 'blue', hex: '#2196F3' }
      case ORDER_STATUS.ASSIGNED:
        return { color: 'blue', hex: '#2196F3' }
      case ORDER_STATUS.CUSTOMER_CANCELLED:
      case ORDER_STATUS.DRIVER_CANCELLED:
      case ORDER_STATUS.ADMIN_CANCELLED:
      case ORDER_STATUS.CANCELLED:
        return { color: 'grey', hex: '#9E9E9E' }
      case ORDER_STATUS.FAILED_TO_DELIVER:
      case ORDER_STATUS.INSUFFICIENT_CREDIT:
        return { color: 'red', hex: '#F44336' }
      case ORDER_STATUS.UNDELIVERED:
        return { color: 'red', hex: '#F44336' }
      case ORDER_STATUS.FULFILLED:
        return { color: 'green', hex: '#4CAF50' }
      default:
        return { color: 'grey', hex: '#9E9E9E' }
    }
  }

  public $getServiceLevelColor() {
    return 'blue-grey darken-1'
  }

  public $getServiceLevelText(serviceLevel?: string) {
    return serviceLevel || 'N.A'
  }

  public $getOrderStatusText(status: string) {
    return _.toUpper(status)
  }

  public $getDeliveryTypeText(type?: string) {
    if (!type) {
      return 'N.A'
    }

    return type
  }

  public $getAddress(address1?: string, address2?: string) {
    return (address1 || '').concat(` ${address2}` || '')
  }

  public $getDate(d: Dayjs) {
    return d.tz(DEFAULT_TZ).format(DEFAULT_FORMAT.DATE_2)
  }

  public $getTime(d: Dayjs | string) {
    return dayjs(d).tz(DEFAULT_TZ).format(DEFAULT_FORMAT.TIME_ONLY)
  }

  public $getDateTime(d: Dayjs) {
    return d.tz(DEFAULT_TZ).format(DEFAULT_FORMAT.DATE_TIME)
  }

  public $getDateTimeFull(d: Dayjs) {
    return d.tz(DEFAULT_TZ).format(DEFAULT_FORMAT.DATE_TIME_3)
  }

  public $getDateTimeMobileView(d: Dayjs) {
    return d.tz(DEFAULT_TZ).format(DEFAULT_FORMAT.DATE_TIME_2)
  }

  public $getShortDateTime(d: Dayjs) {
    return d.tz(DEFAULT_TZ).format(DEFAULT_FORMAT.DATE_TIME_5)
  }

  public $getFullDT(d: Dayjs) {
    return d.tz(DEFAULT_TZ).format(DEFAULT_FORMAT.DATE_TIME_6)
  }

  public $getPrice(p?: string | number, defaultValue = '$0.00') {
    if (!p || !UtilityService.isNumber(p)) return defaultValue

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    return formatter
      .format(parseFloat(p.toString()))
      .replace(/^(?!-).*\$/g, '$')
  }

  public $getAlphabetFromIndex(index: number) {
    return String.fromCharCode(index + 'A'.charCodeAt(0))
  }

  public $getAllCategories() {
    return [
      {
        name: 'General',
        value: 'general',
      },
      {
        name: 'Food',
        value: 'food',
      },
      {
        name: 'Health',
        value: 'health',
      },
      {
        name: 'Gift',
        value: 'gift',
      },
    ]
  }

  public $getAllSize() {
    return [
      {
        name: 'XXS',
        value: '24',
        description: '20*12*10 cm',
        text: '20*12*10 cm (24)',
      },
      {
        name: 'XS',
        value: '40',
        description: '20*20*10 cm',
        text: '20*20*10 cm (40)',
      },
      {
        name: 'S',
        value: '80',
        description: '20*20*20 cm',
        text: '20*20*20 cm (80)',
      },
      {
        name: 'M',
        value: '160',
        description: '40*20*20 cm',
        text: '40*20*20 cm (160)',
      },
      {
        name: 'L',
        value: '1000',
        description: '40*50*50 cm',
        text: '40*50*50 cm (1000)',
      },
      {
        name: 'XL',
        value: '2000',
        description: '80*50*50 cm',
        text: '80*50*50 cm (2000)',
      },
      {
        name: 'XXL',
        value: '4000',
        description: '100*80*50 cm',
        text: '100*80*50 cm (4000)',
      },
    ]
  }

  public $getItemSize(size: number) {
    const allSizes = this.$getAllSize()

    return _.find(allSizes, s => s.value === size.toString())
  }

  public $getDeliveryTypes() {
    return [
      {
        text: 'Race',
        value: DELIVERY_TYPE.RACE,
      },
      {
        text: 'Race Marketplace',
        value: DELIVERY_TYPE.RACE_MPLACE,
      },
      {
        text: 'Pool',
        value: DELIVERY_TYPE.POOL,
      },
      {
        text: 'Pool Marketplace',
        value: DELIVERY_TYPE.POOL_MPLACE,
      },
      {
        text: '4PL',
        value: DELIVERY_TYPE.BULK_4PL,
      },
      {
        text: 'Self Managed',
        value: DELIVERY_TYPE.SELF_MANAGED,
      },
    ]
  }

  public $getVehicleTypes() {
    return [
      {
        text: 'Walker',
        value: VEHICLE_TYPE.WALKER,
      },
      {
        text: 'Bike',
        value: VEHICLE_TYPE.BIKE,
      },
      {
        text: 'Car',
        value: VEHICLE_TYPE.CAR,
      },
      {
        text: 'Minivan',
        value: VEHICLE_TYPE.MINIVAN,
      },
      {
        text: 'Van',
        value: VEHICLE_TYPE.VAN,
      },
    ]
  }

  public $getProviders() {
    return [
      {
        text: 'DLVRD',
        value: PROVIDER.DLVRD,
      },
      {
        text: 'DLVRD Priority',
        value: PROVIDER.DLVRD_PRIORITY,
      },
      {
        text: 'Lalamove',
        value: PROVIDER.LALAMOVE,
      },
      {
        text: 'Milkrun',
        value: PROVIDER.MILKRUN,
      },
      {
        text: 'GrabExpress',
        value: PROVIDER.GRAB,
      },
      {
        text: 'Pandago',
        value: PROVIDER.PANDAGO,
      },
    ]
  }

  public $getStopType(type: string) {
    return type === WAYPOINT_TYPE.PICK_UP
      ? { icon: 'mdi-tray-arrow-up', color: 'red', text: 'Pick-up' }
      : {
          icon: 'mdi-tray-arrow-down',
          color: 'green darken-1',
          text: 'Drop-off',
        }
  }

  public $getServiceStatus(status: { enabled: boolean }, shortHand = false) {
    if (status.enabled) {
      return {
        text: shortHand ? 'E' : 'Enabled',
        color: 'primary',
      }
    } else {
      return {
        text: shortHand ? 'D' : 'Disabled',
        color: 'grey',
      }
    }
  }

  public $getPriority() {
    return [
      {
        text: 'Highest',
        value: 1,
        color: 'red darken-3',
      },
      {
        text: 'Higher',
        value: 5,
        color: 'red lighten-1',
      },
      {
        text: 'Normal',
        value: 10,
        color: 'orange lighten-2',
      },
      {
        text: 'Low',
        value: 15,
        color: 'blue darken-2',
      },
      {
        text: 'Lowest',
        value: 20,
        color: 'blue lighten-2',
      },
    ]
  }

  public $getNoticeboardTypes() {
    return [
      {
        text: OPS_NOTICEBOARD_TYPE.OPS,
        value: OPS_NOTICEBOARD_TYPE.OPS,
        color: 'orange lighten-2',
      },
      {
        text: OPS_NOTICEBOARD_TYPE.MERCHANT,
        value: OPS_NOTICEBOARD_TYPE.MERCHANT,
        color: 'blue darken-2',
      },
    ]
  }

  public $getDaysOfWeek() {
    return [
      {
        text: 'Mon',
        value: 'Monday',
      },
      {
        text: 'Tues',
        value: 'Tuesday',
      },
      {
        text: 'Wed',
        value: 'Wednesday',
      },
      {
        text: 'Thu',
        value: 'Thursday',
      },
      {
        text: 'Fri',
        value: 'Friday',
      },
      {
        text: 'Sat',
        value: 'Saturday',
      },
      {
        text: 'Sun',
        value: 'Sunday',
      },
    ]
  }

  public $getAllWindows() {
    return _.chain(24)
      .times()
      .map(hour => {
        return _.times(4).map((_mins, index) => {
          const h = _.padStart(hour.toString(), 2, '0')
          const m = _.padStart((15 * index).toString(), 2, '0')
          return {
            text: `${h}:${m}`,
            value: `${h}:${m}`,
          }
        })
      })
      .flatten()
      .value()
  }
}
