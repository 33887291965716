import gql from 'graphql-tag'

export const PlaceDeliveryOrderGQL = gql`
  mutation (
    $userObjectId: String!
    $delivery: PlaceDeliveryOrderDeliveryRequest
    $multiDates: [String]
  ) {
    placeDeliveryOrder(
      userObjectId: $userObjectId
      delivery: $delivery
      multiDates: $multiDates
    ) {
      result {
        date
        deliveries {
          code
          objectId
        }
      }
    }
  }
`
