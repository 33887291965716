import gql from 'graphql-tag'

export const ConfirmUpdatePodUploadGQL = gql`
  mutation ($fileUploadId: Int!, $fileName: String!, $dlvrdDocumentId: String) {
    confirmUpdatePodUpload(
      fileUploadId: $fileUploadId
      fileName: $fileName
      dlvrdDocumentId: $dlvrdDocumentId
    )
  }
`
